import { Register, Edit } from "./page";
// function App() {
//   return (
//     <div>
//       <Register />
//     </div>
//   );
// }

// export default App;
import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppRouter />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AppRouter() {
  let query = useQuery();
  return query.get("q") === "KfxwjOE4qm" ? (
    <Register is_register_open={true} />
  ) : (
    <Register is_register_open={true} />
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
