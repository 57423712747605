// import "./App.css";
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  RadioGroup,
  Radio,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import config from "../config.json";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SchoolIcon from "@mui/icons-material/School";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import WorkIcon from "@mui/icons-material/Work";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import { useLocation } from "react-router-dom";

import { Spinner, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
const theme = createTheme();
function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function Edit() {
  let tid = makeid(20);
  let query = useQuery();
  let [usermeta, setUsermeta] = React.useState({
    jobStatus: "",
    // for studying
    studyLevel: "", //null,
    studyPlace: "",
    studyPlaceProvince: "", //null,
    // for Children
    parentName: "",
    parentPhonenumber: "",
    // for highschool
    studyHighschoolPlan: "", //null,
    otherHighschoolPlan: "",
    // for normal highschool
    studyHighschoolLevel: "", //null,
    // for University
    studyYear: "", //null,
    studyFaculty: "", //null,
    otherFaculty: "",
    studyGroup: "", //null,
    studyBranch: "", //null,
    otherStudyBranch: "", //null,
    // For grated level
    researchArea: "",
    // for working or unemployed
    maxStudyLevel: "", //null,
    graduatedUniversity: "",
    // for working
    jobType: "", //null,
    organizationName: "",
    jobPosition: "",
    jobDivision: "",
    jobAge: "",
  });
  let [validumetastate, setValidumetastate] = React.useState({
    jobStatus: true,
    studyLevel: true,
    studyPlace: true,
    studyPlaceProvince: true,
    parentName: true,
    parentPhonenumber: true,
    studyHighschoolPlan: true,
    otherHighschoolPlan: true,
    studyHighschoolLevel: true,
    studyYear: true,
    studyFaculty: true,
    otherFaculty: true,
    studyGroup: true,
    studyBranch: true,
    otherStudyBranch: true,
    maxStudyLevel: true,
    graduatedUniversity: true,
    jobType: true,
    organizationName: true,
    jobPosition: true,
    jobDivision: true,
    jobAge: true,
  });
  const [iswaiting, setIswaiting] = React.useState(false);
  const [isregistersuccess, setIsregistersuccess] = React.useState(false);

  const handleChangeUserMeta = (e) => {
    const { name, value } = e.target;
    setUsermeta((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "parentPhonenumber") {
      if (value === "" || !/^0[0-9]{9}$/.test(value.replace(" ", ""))) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else {
      if (value === "") {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }
  };

  function check_cond_before_submit() {
    let cond_state = true;
    let local_umeta_valid_state = { ...validumetastate };
    let local_umeta_state = { ...usermeta };
    // ["email","firstname","lastname","phonenumber"]
    let arrName = ["jobStatus"];
    for (const idx in arrName) {
      let name = arrName[idx];
      if (validumetastate[name] && usermeta[name] !== "") {
        local_umeta_valid_state[name] = true;
      } else {
        local_umeta_valid_state[name] = false;
        cond_state = false;
      }
    }
    if (usermeta.jobStatus === "เรียน") {
      let arrName = ["studyLevel"];
      for (const idx in arrName) {
        let name = arrName[idx];
        if (validumetastate[name] && usermeta[name] !== "") {
          local_umeta_valid_state[name] = true;
        } else {
          local_umeta_valid_state[name] = false;
          cond_state = false;
        }
      }
      if (
        usermeta.studyLevel === "ประถมศึกษา" ||
        usermeta.studyLevel === "มัธยมศึกษาตอนต้น"
      ) {
        let arrName = ["studyPlace", "studyPlaceProvince", "parentName"];
        for (const idx in arrName) {
          let name = arrName[idx];
          if (validumetastate[name] && usermeta[name] !== "") {
            local_umeta_valid_state[name] = true;
          } else {
            local_umeta_valid_state[name] = false;
            cond_state = false;
          }
        }
        if (
          validumetastate.parentPhonenumber &&
          (usermeta.parentPhonenumber !== "" ||
            !/^0[0-9]{9}$/.test(usermeta.parentPhonenumber.replace(" ", "")))
        ) {
          local_umeta_valid_state.parentPhonenumber = true;
        } else {
          local_umeta_valid_state.parentPhonenumber = false;
          cond_state = false;
        }
        local_umeta_state = {
          ...local_umeta_state,
          // for studying
          // studyLevel: "",
          // studyPlace: "",
          // studyPlaceProvince: "",
          // for Children
          // parentName: "",
          // parentPhonenumber: "",
          // for highschool
          studyHighschoolPlan: "",
          otherHighschoolPlan: "",
          // for normal highschool
          studyHighschoolLevel: "",
          // for University
          studyYear: "",
          studyFaculty: "",
          otherFaculty: "",
          studyGroup: "",
          studyBranch: "",
          otherStudyBranch: "",
          // For grated level
          researchArea: "",
          // for working or unemployed
          maxStudyLevel: "",
          graduatedUniversity: "",
          // for working
          jobType: "",
          organizationName: "",
          jobPosition: "",
          jobDivision: "",
          jobAge: "",
        };
      } else if (usermeta.studyLevel === "มัธยมศึกษาตอนปลายหรือเทียบเท่า") {
        let arrName = [
          "studyPlace",
          "studyPlaceProvince",
          "studyHighschoolPlan",
          // "otherHighschoolPlan",
          usermeta.studyHighschoolPlan === "อื่นๆ"
            ? "otherHighschoolPlan"
            : null,
          "studyHighschoolLevel",
        ];
        for (const idx in arrName) {
          let name = arrName[idx];
          if (name !== null) {
            if (validumetastate[name] && usermeta[name] !== "") {
              local_umeta_valid_state[name] = true;
            } else {
              local_umeta_valid_state[name] = false;
              cond_state = false;
            }
          }
        }
        local_umeta_state = {
          ...local_umeta_state,
          // for studying
          // studyLevel: "",
          // studyPlace: "",
          // studyPlaceProvince: "",
          // for Children
          parentName: "",
          parentPhonenumber: "",
          // for highschool
          // studyHighschoolPlan: "",
          // otherHighschoolPlan: "",
          // for normal highschool
          // studyHighschoolLevel: "",
          // for University
          studyYear: "",
          studyFaculty: "",
          otherFaculty: "",
          studyGroup: "",
          studyBranch: "",
          otherStudyBranch: "",
          // For grated level
          researchArea: "",
          // for working or unemployed
          maxStudyLevel: "",
          graduatedUniversity: "",
          // for working
          jobType: "",
          organizationName: "",
          jobPosition: "",
          jobDivision: "",
          jobAge: "",
        };
      } else if (usermeta.studyLevel === "อนุปริญญาหรือเทียบเท่า") {
        let arrName = [
          "studyPlace",
          "studyPlaceProvince",
          "studyHighschoolPlan",
          usermeta.studyHighschoolPlan === "อื่นๆ"
            ? "otherHighschoolPlan"
            : null,
        ];
        for (const idx in arrName) {
          let name = arrName[idx];
          if (name !== null) {
            if (validumetastate[name] && usermeta[name] !== "") {
              local_umeta_valid_state[name] = true;
            } else {
              local_umeta_valid_state[name] = false;
              cond_state = false;
            }
          }
        }
        local_umeta_state = {
          ...local_umeta_state,
          // for studying
          // studyLevel: "",
          // studyPlace: "",
          // studyPlaceProvince: "",
          // for Children
          parentName: "",
          parentPhonenumber: "",
          // for highschool
          // studyHighschoolPlan: "",
          // otherHighschoolPlan: "",
          // for normal highschool
          studyHighschoolLevel: "",
          // for University
          studyYear: "",
          studyFaculty: "",
          otherFaculty: "",
          studyGroup: "",
          studyBranch: "",
          otherStudyBranch: "",
          // For grated level
          researchArea: "",
          // for working or unemployed
          maxStudyLevel: "",
          graduatedUniversity: "",
          // for working
          jobType: "",
          organizationName: "",
          jobPosition: "",
          jobDivision: "",
          jobAge: "",
        };
      } else if (
        usermeta.studyLevel === "ปริญญาตรี" ||
        usermeta.studyLevel === "ปริญญาโท" ||
        usermeta.studyLevel === "ปริญญาเอก"
      ) {
        let arrName = [
          // "studyYear",
          usermeta.studyLevel === "ปริญญาตรี" ? "studyYear" : null,
          "studyFaculty",
          "studyGroup",
          "studyBranch",
          usermeta.studyFaculty === "อื่นๆ" ? "otherFaculty" : null,
          usermeta.studyBranch === "อื่นๆ" ? "otherStudyBranch" : null,
        ];
        for (const idx in arrName) {
          let name = arrName[idx];
          if (name !== null) {
            if (validumetastate[name] && usermeta[name] !== "") {
              local_umeta_valid_state[name] = true;
            } else {
              local_umeta_valid_state[name] = false;
              cond_state = false;
            }
          }
        }
        local_umeta_state = {
          ...local_umeta_state,
          // for studying
          // studyLevel: "",
          // studyPlace: "",
          // studyPlaceProvince: "",
          // for Children
          parentName: "",
          parentPhonenumber: "",
          // for highschool
          studyHighschoolPlan: "",
          otherHighschoolPlan: "",
          // for normal highschool
          studyHighschoolLevel: "",
          // for University
          // studyYear: "",
          // studyFaculty: "",
          // otherFaculty: "",
          // studyGroup: "",
          // studyBranch: "",
          // otherStudyBranch: "",
          // For grated level
          // researchArea: "",
          // for working or unemployed
          maxStudyLevel: "",
          graduatedUniversity: "",
          // for working
          jobType: "",
          organizationName: "",
          jobPosition: "",
          jobDivision: "",
          jobAge: "",
        };
      }
    } else if (usermeta.jobStatus === "ทำงาน") {
      let arrName = [
        "maxStudyLevel",
        "graduatedUniversity",
        "jobType",
        "organizationName",
        "jobPosition",
        "jobDivision",
        "jobAge",
      ];
      for (const idx in arrName) {
        let name = arrName[idx];
        if (validumetastate[name] && usermeta[name] !== "") {
          local_umeta_valid_state[name] = true;
        } else {
          local_umeta_valid_state[name] = false;
          cond_state = false;
        }
      }
      local_umeta_state = {
        ...local_umeta_state,
        // for studying
        studyLevel: "",
        studyPlace: "",
        studyPlaceProvince: "",
        // for Children
        parentName: "",
        parentPhonenumber: "",
        // for highschool
        studyHighschoolPlan: "",
        otherHighschoolPlan: "",
        // for normal highschool
        studyHighschoolLevel: "",
        // for University
        studyYear: "",
        studyFaculty: "",
        otherFaculty: "",
        studyGroup: "",
        studyBranch: "",
        otherStudyBranch: "",
        // For grated level
        researchArea: "",
        // for working or unemployed
        // maxStudyLevel: "",
        // graduatedUniversity: "",
        // for working
        // jobType: "",
        // organizationName: "",
        // jobPosition: "",
        // jobDivision: "",
        // jobAge: "",
      };
    } else if (usermeta.jobStatus === "ว่างงาน") {
      if (validumetastate.maxStudyLevel && usermeta.maxStudyLevel !== "") {
        local_umeta_valid_state.maxStudyLevel = true;
      } else {
        local_umeta_valid_state.maxStudyLevel = false;
        cond_state = false;
      }
      if (
        validumetastate.graduatedUniversity &&
        usermeta.graduatedUniversity !== ""
      ) {
        local_umeta_valid_state.graduatedUniversity = true;
      } else {
        local_umeta_valid_state.graduatedUniversity = false;
        cond_state = false;
      }
      local_umeta_state = {
        ...local_umeta_state,
        // for studying
        studyLevel: "",
        studyPlace: "",
        studyPlaceProvince: "",
        // for Children
        parentName: "",
        parentPhonenumber: "",
        // for highschool
        studyHighschoolPlan: "",
        otherHighschoolPlan: "",
        // for normal highschool
        studyHighschoolLevel: "",
        // for University
        studyYear: "",
        studyFaculty: "",
        otherFaculty: "",
        studyGroup: "",
        studyBranch: "",
        otherStudyBranch: "",
        // For grated level
        researchArea: "",
        // for working or unemployed
        // maxStudyLevel: "",
        // graduatedUniversity: "",
        // for working
        jobType: "",
        organizationName: "",
        jobPosition: "",
        jobDivision: "",
        jobAge: "",
      };
    } else {
    }

    setUsermeta(local_umeta_state);
    setValidumetastate(local_umeta_valid_state);
    return cond_state;
  }
  async function sentregister() {
    if (check_cond_before_submit()) {
      setIswaiting(true);
      // Fetch data to be
      let response = await fetch(config.backend_url + "/report/editdata", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        body: JSON.stringify({
          tid: tid,
          u: query.get("u"),
          usermeta: {
            ...usermeta,
          },
        }),
        // AESencrypt(
        //   JSON.stringify(state),
        //   config.aes_key
        // ),
      });
      if (response.status === 200) {
        setIsregistersuccess(true);
      } else {
        let data = await response.json();
        document.getElementById("warning").innerHTML = data["detail"];
        setIswaiting(false);
      }
    } else {
      document.getElementById("warning").innerHTML =
        "กรุณากรอกข้อมูลให้ครบและถูกต้อง";
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        //  maxWidth="xs"
        maxWidth="s"
        // maxWidth="300px"
        style={{
          display: "flex",
          // alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CssBaseline />

        {!isregistersuccess ? (
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // paddingLeft: "5vh",
              // paddingRight: "5vh",
              width: "95%",
            }}
            // sx={{ display: 'flex', flexWrap: 'wrap' }}
          >
            <img
              src="/AIRoboticLogo.png"
              alt=""
              style={{
                maxWidth: "700px",
                width: "100%",
                marginBottom: "8px",
              }}
            />
            <Typography
              component="h1"
              variant="h5"
              style={{
                fontWeight: 600,
                marginTop: "4px",
                marginBottom: "-8px",
              }}
            >
              Edit Registration Form
            </Typography>

            <Box
              noValidate
              sx={{
                mt: 1,
              }}
              style={{ padding: "8px" }}
            >
              <Row>
                <Typography component="h6" variant="h6">
                  <SchoolIcon
                    style={{
                      height: "25px",
                      width: "25px",
                      marginRight: "4px",
                    }}
                  />
                  ข้อมูลระดับการศึกษาและการทำงาน
                </Typography>
              </Row>
              <Row>
                <Typography
                  component="p"
                  variant="p"
                  style={!validumetastate.jobStatus ? { color: "red" } : {}}
                >
                  สถานะภาพของท่านในปัจจุบัน
                </Typography>
                <RadioGroup
                  // defaultValue="เรียน"
                  name="jobStatus"
                  value={usermeta.jobStatus}
                  onChange={handleChangeUserMeta}
                  row
                >
                  <FormControlLabel
                    value="เรียน"
                    control={<Radio />}
                    label="เรียน"
                  />
                  <FormControlLabel
                    value="ทำงาน"
                    control={<Radio />}
                    label="ทำงาน"
                  />
                  <FormControlLabel
                    value="ว่างงาน"
                    control={<Radio />}
                    label="ว่างงาน"
                  />
                </RadioGroup>
              </Row>
              {usermeta.jobStatus === "เรียน" && (
                <div>
                  <Row>
                    <Typography component="p" variant="p">
                      <LocalLibraryIcon
                        style={{
                          height: "25px",
                          width: "25px",
                          marginRight: "4px",
                        }}
                      />
                      กำลังศึกษา
                    </Typography>
                  </Row>
                  <Row>
                    <Col>
                      <FormControl
                        variant="standard"
                        margin="normal"
                        style={{ width: "100%", minWidth: "250px" }}
                        fullWidth
                        error={!validumetastate.studyLevel}
                      >
                        <InputLabel>{"กำลังการศึกษาในระดับ"}</InputLabel>
                        <Select
                          value={usermeta.studyLevel}
                          name="studyLevel"
                          onChange={handleChangeUserMeta}
                          // fullWidth
                        >
                          {[
                            "ประถมศึกษา",
                            "มัธยมศึกษาตอนต้น",
                            "มัธยมศึกษาตอนปลายหรือเทียบเท่า",
                            "อนุปริญญาหรือเทียบเท่า",
                            "ปริญญาตรี",
                            "ปริญญาโท",
                            "ปริญญาเอก",
                          ].map((v) => {
                            return <MenuItem value={v}>{v}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="sm-4">
                      <TextField
                        margin="normal"
                        // fullWidth
                        style={{ width: "100%", minWidth: "250px" }}
                        label="สถาบันการศึกษา"
                        name="studyPlace"
                        value={usermeta.studyPlace}
                        onChange={handleChangeUserMeta}
                        //  autoFocus
                        helperText="ให้ใส่โรงเรียน หรือมหาวิทยาลัยนำหน้าชื่อสถาบันการศึกษา"
                        error={!validumetastate.studyPlace}
                      />
                    </Col>
                    <Col className="sm-4">
                      <FormControl
                        variant="standard"
                        margin="normal"
                        style={{ width: "100%", minWidth: "250px" }}
                        fullWidth
                        error={!validumetastate.studyPlaceProvince}
                      >
                        <InputLabel>
                          {"จังหวัดที่ตั้งของสถาบันการศึกษา"}
                        </InputLabel>
                        <Select
                          value={usermeta.studyPlaceProvince}
                          name="studyPlaceProvince"
                          onChange={handleChangeUserMeta}
                          // fullWidth
                        >
                          {config.provinceList.map((v) => {
                            return <MenuItem value={v}>{v}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  {(usermeta.studyLevel === "ประถมศึกษา" ||
                    usermeta.studyLevel === "มัธยมศึกษาตอนต้น") && (
                    <Row>
                      <Col className="sm-4">
                        <TextField
                          margin="normal"
                          // fullWidth
                          style={{ width: "100%", minWidth: "250px" }}
                          label="ชื่อ - นามสกุลผู้ปกครอง"
                          name="parentName"
                          value={usermeta.parentName}
                          onChange={handleChangeUserMeta}
                          //  autoFocus
                          error={!validumetastate.parentName}
                        />
                      </Col>
                      <Col className="sm-4">
                        <TextField
                          margin="normal"
                          // fullWidth
                          style={{ width: "100%", minWidth: "250px" }}
                          label="เบอร์โทรศัพท์ผู้ปกครอง"
                          name="parentPhonenumber"
                          value={usermeta.parentPhonenumber}
                          onChange={handleChangeUserMeta}
                          //  autoFocus
                          helperText="กรอกในรูปแบบ 0xxxxxxxxx"
                          error={!validumetastate.parentPhonenumber}
                        />
                      </Col>
                    </Row>
                  )}
                  {(usermeta.studyLevel === "มัธยมศึกษาตอนปลายหรือเทียบเท่า" ||
                    usermeta.studyLevel === "อนุปริญญาหรือเทียบเท่า") && (
                    <div>
                      <Row>
                        <Col className="sm-4">
                          <FormControl
                            variant="standard"
                            margin="normal"
                            style={{ width: "100%", minWidth: "250px" }}
                            fullWidth
                            error={!validumetastate.studyHighschoolPlan}
                          >
                            <InputLabel>{"แผนการเรียน"}</InputLabel>
                            <Select
                              value={usermeta.studyHighschoolPlan}
                              name="studyHighschoolPlan"
                              onChange={handleChangeUserMeta}
                              // fullWidth
                            >
                              {[
                                "สายวิทย์-คณิต",
                                "สายศิลป์-คำนวณ",
                                "สายศิลป์-ภาษา",
                                "สายคอมพิวเตอร์ธุรกิจ",
                                "สายอิเล็กทรอนิกส์",
                                "สายไฟฟ้ากำลัง",
                                "สายเครื่องกล",
                                "อื่นๆ",
                              ].map((v) => {
                                return <MenuItem value={v}>{v}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col className="sm-4">
                          {usermeta.studyHighschoolPlan === "อื่นๆ" && (
                            <TextField
                              margin="normal"
                              // fullWidth
                              style={{ width: "100%", minWidth: "250px" }}
                              label="ระบุแผนการเรียน"
                              name="otherHighschoolPlan"
                              value={usermeta.otherHighschoolPlan}
                              onChange={handleChangeUserMeta}
                              //  autoFocus
                              error={!validumetastate.otherHighschoolPlan}
                            />
                          )}
                        </Col>
                      </Row>
                      {usermeta.studyLevel ===
                        "มัธยมศึกษาตอนปลายหรือเทียบเท่า" && (
                        <Row>
                          <Col className="sm-4">
                            <FormControl
                              variant="standard"
                              margin="normal"
                              style={{ width: "100%", minWidth: "250px" }}
                              fullWidth
                              error={!validumetastate.studyHighschoolLevel}
                            >
                              <InputLabel>{"ระดับชั้น"}</InputLabel>
                              <Select
                                value={usermeta.studyHighschoolLevel}
                                name="studyHighschoolLevel"
                                onChange={handleChangeUserMeta}
                                // fullWidth
                              >
                                {[
                                  "ม.4 หรือเทียบเท่า",
                                  "ม.5 หรือเทียบเท่า",
                                  "ม.6 หรือเทียบเท่า",
                                ].map((v) => {
                                  return <MenuItem value={v}>{v}</MenuItem>;
                                })}
                              </Select>
                            </FormControl>
                          </Col>
                          <Col className="sm-4"></Col>
                        </Row>
                      )}
                    </div>
                  )}
                  {(usermeta.studyLevel === "ปริญญาตรี" ||
                    usermeta.studyLevel === "ปริญญาโท" ||
                    usermeta.studyLevel === "ปริญญาเอก") && (
                    <div>
                      {usermeta.studyLevel === "ปริญญาตรี" && (
                        <Row>
                          <Col className="sm-4">
                            <FormControl
                              variant="standard"
                              margin="normal"
                              style={{ width: "100%", minWidth: "250px" }}
                              fullWidth
                              error={!validumetastate.studyYear}
                            >
                              <InputLabel>{"ชั้นปี"}</InputLabel>
                              <Select
                                value={usermeta.studyYear}
                                name="studyYear"
                                onChange={handleChangeUserMeta}
                                // fullWidth
                              >
                                {["1", "2", "3", "4 หรือมากกว่า"].map((v) => {
                                  return <MenuItem value={v}>{v}</MenuItem>;
                                })}
                              </Select>
                            </FormControl>
                          </Col>
                          <Col className="sm-4">
                            <div
                              style={{ width: "100%", minWidth: "250px" }}
                            ></div>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col className="sm-4">
                          <FormControl
                            variant="standard"
                            margin="normal"
                            style={{ width: "100%", minWidth: "250px" }}
                            fullWidth
                            error={!validumetastate.studyFaculty}
                          >
                            <InputLabel>{"คณะ"}</InputLabel>
                            <Select
                              value={usermeta.studyFaculty}
                              name="studyFaculty"
                              onChange={handleChangeUserMeta}
                              // fullWidth
                            >
                              {[
                                "คณะเกษตรศาสตร์",
                                "คณะครุศาสตร์อุตสาหกรรม",
                                "คณะเทคโนโลยี",
                                "คณะเทคโนโลยีสารสนเทศ",
                                "คณะประมง",
                                "คณะวิทยาศาสตร์",
                                "คณะวิศวกรรมศาสตร์",
                                "คณะสิ่งแวดล้อม",
                                "คณะกายภาพบำบัด",
                                "คณะการแพทย์แผนไทย",
                                "คณะทันตแพทยศาสตร์",
                                "คณะเทคนิคการแพทย์",
                                "คณะแพทยศาสตร์",
                                "คณะพยาบาลศาสตร์",
                                "คณะเภสัชศาสตร์",
                                "คณะสหเวชศาสตร์",
                                "คณะสัตวแพทยศาสตร์",
                                "คณะสาธารณสุขศาสตร์",
                                "คณะนิติศาสตร์",
                                "คณะนิเทศศาสตร์",
                                "คณะบริหารธุรกิจและการบัญชี",
                                "คณะมนุษยศาสตร์",
                                "คณะรัฐศาสตร์",
                                "คณะศิลปกรรมศาสตร์",
                                "คณะศึกษาศาสตร์",
                                "คณะเศรษฐศาสตร์",
                                "คณะสถาปัตยกรรมศาสตร์",
                                "คณะสังคมศาสตร์",
                                "คณะสังคมสงเคราะห์ศาสตร์",
                                "อื่นๆ",
                              ].map((v) => {
                                return <MenuItem value={v}>{v}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col className="sm-4">
                          {usermeta.studyFaculty === "อื่นๆ" && (
                            <TextField
                              margin="normal"
                              // fullWidth
                              style={{ width: "100%", minWidth: "250px" }}
                              label="ระบุคณะหรือโครงการ"
                              name="otherFaculty"
                              value={usermeta.otherFaculty}
                              onChange={handleChangeUserMeta}
                              //  autoFocus
                              error={!validumetastate.otherFaculty}
                            />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sm-4">
                          <FormControl
                            variant="standard"
                            margin="normal"
                            style={{ width: "100%", minWidth: "250px" }}
                            fullWidth
                            error={!validumetastate.studyGroup}
                          >
                            <InputLabel>{"กลุ่มสาขาวิชา"}</InputLabel>
                            <Select
                              value={usermeta.studyGroup}
                              name="studyGroup"
                              onChange={handleChangeUserMeta}
                              // fullWidth
                            >
                              {[
                                "วิศวกรรมศาสตร์ วิทยาศาสตร์และเทคโนโลยี",
                                "วิทยาศาสตร์สุขภาพ",
                                "สังคมสงเคราะห์ ศิลปศาสตร์ มนุษยศาสตร์ ศึกษาศาสตร์",
                                "ศิลปกรรมศาสตร์ สถาปัตยกรรมศาสตร์",
                                "เกษตรศาสตร์",
                              ].map((v) => {
                                return <MenuItem value={v}>{v}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col className="sm-4">
                          {usermeta.studyGroup && (
                            <FormControl
                              variant="standard"
                              margin="normal"
                              style={{ width: "100%", minWidth: "250px" }}
                              fullWidth
                              error={!validumetastate.studyBranch}
                            >
                              <InputLabel>
                                {"สาขา" + usermeta.studyGroup}
                              </InputLabel>
                              <Select
                                value={usermeta.studyBranch}
                                name="studyBranch"
                                onChange={handleChangeUserMeta}
                                // fullWidth
                              >
                                {config.branchList[usermeta.studyGroup].map(
                                  (v) => {
                                    return <MenuItem value={v}>{v}</MenuItem>;
                                  }
                                )}
                              </Select>
                            </FormControl>
                          )}
                        </Col>
                      </Row>
                      {usermeta.studyBranch === "อื่นๆ" && (
                        <Row>
                          <Col className="sm-4"></Col>
                          <Col className="sm-4">
                            <TextField
                              margin="normal"
                              // fullWidth
                              style={{ width: "100%", minWidth: "250px" }}
                              label="สาขาวิชา อื่น ๆ"
                              name="otherStudyBranch"
                              value={usermeta.otherStudyBranch}
                              onChange={handleChangeUserMeta}
                              //  autoFocus
                              error={!validumetastate.otherStudyBranch}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          {(usermeta.studyLevel === "ปริญญาโท" ||
                            usermeta.studyLevel === "ปริญญาเอก") && (
                            <TextField
                              margin="normal"
                              // fullWidth
                              style={{ width: "100%", minWidth: "250px" }}
                              label="หัวข้อวิจัย"
                              name="researchArea"
                              value={usermeta.researchArea}
                              onChange={handleChangeUserMeta}
                              // autoFocus
                              helperText="หัวข้อวิจัยที่กำลังทำอยู่ แม้ว่าหัวข้อนั้นยังไม่ได้สอบก็ตาม เป็นเพียงข้อมูลเพิ่มเติมเท่านั้น"
                              // error={!validumetastate.researchArea}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              )}
              {usermeta.jobStatus === "ทำงาน" && (
                <div>
                  <Row>
                    <Typography component="p" variant="p">
                      <WorkIcon
                        style={{
                          height: "25px",
                          width: "25px",
                          marginRight: "4px",
                        }}
                      />
                      ทำงานหรือว่างงาน
                    </Typography>
                  </Row>
                  <Row>
                    <Col className="sm-4">
                      <FormControl
                        variant="standard"
                        margin="normal"
                        style={{ width: "100%", minWidth: "250px" }}
                        fullWidth
                        error={!validumetastate.maxStudyLevel}
                      >
                        <InputLabel>{"การศึกษาสูงสุด"}</InputLabel>
                        <Select
                          value={usermeta.maxStudyLevel}
                          name="maxStudyLevel"
                          onChange={handleChangeUserMeta}
                          // fullWidth
                        >
                          {[
                            "ประถมศึกษา",
                            "มัธยมศึกษาตอนต้น",
                            "มัธยมศึกษาตอนปลายหรือเทียบเท่า",
                            "อนุปริญญาหรือเทียบเท่า",
                            "ปริญญาตรี",
                            "ปริญญาโท",
                            "ปริญญาเอก",
                          ].map((v) => {
                            return <MenuItem value={v}>{v}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col className="sm-4">
                      <TextField
                        margin="normal"
                        // fullWidth
                        style={{ width: "100%", minWidth: "250px" }}
                        label="สถาบันที่จบการศึกษา"
                        name="graduatedUniversity"
                        value={usermeta.graduatedUniversity}
                        onChange={handleChangeUserMeta}
                        //  autoFocus
                        helperText="ให้ใส่โรงเรียน หรือมหาวิทยาลัยนำหน้าชื่อสถาบันการศึกษา"
                        error={!validumetastate.graduatedUniversity}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Typography component="p" variant="p">
                      <EqualizerIcon
                        style={{
                          height: "25px",
                          width: "25px",
                          marginRight: "4px",
                        }}
                      />
                      ข้อมูลการทำงานปัจจุบัน
                    </Typography>
                  </Row>
                  <Row>
                    <Col className="sm-4">
                      <FormControl
                        variant="standard"
                        margin="normal"
                        style={{ width: "100%", minWidth: "250px" }}
                        fullWidth
                        error={!validumetastate.jobType}
                      >
                        <InputLabel>{"ประเภทอาชีพ"}</InputLabel>
                        <Select
                          value={usermeta.jobType}
                          name="jobType"
                          onChange={handleChangeUserMeta}
                          // fullWidth
                        >
                          {[
                            "พนักงานบริษัทเอกชน",
                            "พนักงานองค์กรภาครัฐ",
                            "เจ้าของธุรกิจ",
                            "วิทยากร ครู หรืออาจารย์",
                            "อื่นๆ",
                          ].map((v) => {
                            return <MenuItem value={v}>{v}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col className="sm-4">
                      <TextField
                        margin="normal"
                        // fullWidth
                        style={{ width: "100%", minWidth: "250px" }}
                        label="ชื่อหน่วยงาน"
                        name="organizationName"
                        value={usermeta.organizationName}
                        onChange={handleChangeUserMeta}
                        //  autoFocus
                        error={!validumetastate.organizationName}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="sm-4">
                      <TextField
                        margin="normal"
                        // fullWidth
                        style={{ width: "100%", minWidth: "250px" }}
                        label="ตำแหน่งงาน"
                        name="jobPosition"
                        value={usermeta.jobPosition}
                        onChange={handleChangeUserMeta}
                        //  autoFocus
                        error={!validumetastate.jobPosition}
                      />
                    </Col>
                    <Col className="sm-4">
                      <TextField
                        margin="normal"
                        // fullWidth
                        style={{ width: "100%", minWidth: "250px" }}
                        label="ฝ่าย / แผนก"
                        name="jobDivision"
                        value={usermeta.jobDivision}
                        onChange={handleChangeUserMeta}
                        //  autoFocus
                        helperText="หากไม่มีให้ระบุ - (ขีด)"
                        error={!validumetastate.jobDivision}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="sm-4">
                      <TextField
                        margin="normal"
                        // fullWidth
                        style={{ width: "100%", minWidth: "250px" }}
                        label="อายุงาน (ปี)"
                        name="jobAge"
                        value={usermeta.jobAge}
                        onChange={handleChangeUserMeta}
                        //  autoFocus
                        error={!validumetastate.jobAge}
                      />
                    </Col>
                    <Col className="sm-4">
                      <div style={{ width: "100%", minWidth: "250px" }}></div>
                    </Col>
                  </Row>
                </div>
              )}
              {usermeta.jobStatus === "ว่างงาน" && (
                <div>
                  <Row>
                    <Typography component="p" variant="p">
                      <WorkIcon
                        style={{
                          height: "25px",
                          width: "25px",
                          marginRight: "4px",
                        }}
                      />
                      ทำงานหรือว่างงาน
                    </Typography>
                  </Row>
                  <Row>
                    <Col className="sm-4">
                      <FormControl
                        variant="standard"
                        margin="normal"
                        style={{ width: "100%", minWidth: "250px" }}
                        fullWidth
                        error={!validumetastate.maxStudyLevel}
                      >
                        <InputLabel>{"การศึกษาสูงสุด"}</InputLabel>
                        <Select
                          value={usermeta.maxStudyLevel}
                          name="maxStudyLevel"
                          onChange={handleChangeUserMeta}
                          // fullWidth
                        >
                          {[
                            "ประถมศึกษา",
                            "มัธยมศึกษาตอนต้น",
                            "มัธยมศึกษาตอนปลายหรือเทียบเท่า",
                            "อนุปริญญาหรือเทียบเท่า",
                            "ปริญญาตรี",
                            "ปริญญาโท",
                            "ปริญญาเอก",
                          ].map((v) => {
                            return <MenuItem value={v}>{v}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col className="sm-4">
                      <TextField
                        margin="normal"
                        // fullWidth
                        style={{ width: "100%", minWidth: "250px" }}
                        label="สถาบันที่จบการศึกษา"
                        name="graduatedUniversity"
                        value={usermeta.graduatedUniversity}
                        onChange={handleChangeUserMeta}
                        //  autoFocus
                        helperText="ให้ใส่โรงเรียน หรือมหาวิทยาลัยนำหน้าชื่อสถาบันการศึกษา"
                        error={!validumetastate.graduatedUniversity}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              <div id="warning" style={{ color: "red" }}></div>
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={sentregister}
              >
                {!iswaiting ? (
                  <div>Submit</div>
                ) : (
                  <div>
                    <Spinner animation="border" variant="light" size="sm" />{" "}
                    Waiting...
                  </div>
                )}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // maxWidth: "480px",
            }}
          >
            <CheckCircleIcon
              style={{
                fontSize: "64px",
                width: "100%",
                margin: "auto",
                color: "#49AA19",
              }}
            />
            <div
              style={{
                fontSize: "20px",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Congratulations!
            </div>
            <div
              style={{
                marginBottom: "16px",
                padding: "16px",
                textAlign: "center",
                fontSize: "16px",
                maxWidth: "540px",
              }}
            >
              {/* You have successfully register to AI/Robotics for All (Season 3).
                We have sent your username and password in your email. If you can
                not see, please check in your junk mail. */}
              {/* You have successfully register to AI/Robotics for All (Season 3).
                We will send your username and password in your email in 24 hours.
                If you can not see, please check in your junk mail.
                <br /> */}
              คุณได้แก้ไขข้อมูลเสร็จเรียบร้อยแล้ว
            </div>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
}
