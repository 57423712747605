// import "./App.css";
import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  RadioGroup,
  Radio,
  Checkbox,
  Grid,
} from "@mui/material";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import config from "./../config.json";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";

import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#031d78",
    },
  },
});

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export default function Register(props) {
  let is_register_open = props.is_register_open ? true : false;
  let [state, setState] = React.useState({
    tid: makeid(20),
    firstname: "",
    lastname: "",
    email: "",
    emailAgain: "",
    phonenumber: "",
    time: new Date(Date.now()).toISOString(),
  });
  let [usermeta, setUsermeta] = React.useState({
    namePrefix: "", //null,
    userrank: "",
    firstnameEN: "",
    lastnameEN: "",
    dateOfBirth: "", //null,
    recidenceAmphoe: "",
    recidenceProvince: "", //null,
    jobStatus: "",
    // for studying
    studyLevel: "", //null,
    studyPlace: "",
    studyPlaceProvince: "", //null,
    // for Children
    parentName: "",
    parentPhonenumber: "",
    // for highschool
    studyHighschoolPlan: "", //null,
    otherHighschoolPlan: "",
    // for normal highschool
    studyHighschoolLevel: "", //null,
    // for University
    studyYear: "", //null,
    studyFaculty: "", //null,
    otherFaculty: "",
    studyGroup: "", //null,
    studyBranch: "", //null,
    otherStudyBranch: "", //null,
    // For grated level
    researchArea: "",
    // for working or unemployed
    maxStudyLevel: "", //null,
    graduatedUniversity: "",
    // for working
    jobType: "", //null,
    otherOccupation: "",
    organizationName: "",
    jobPosition: "",
    jobDivision: "",
    jobAge: "",
    // Computer Language
    bestProgrammingLanguage: "", //null,
    otherBestProgrammingLanguage: "",
    bestProgrammingLevel: "", //null,

    expertTopic: "",
    AIExpertTopics: [],
    InterestedAITopics: [],

    target: [], // null,
    otherTarget: "",

    willDevelop: "",
    haveAIExp: "",
    interestedLV2: "", // "ไม่มี", // "",
    acceptRecording: "",

    source: [],
    otherSource: "",

    acceptNDA: false,
  });
  let [validstate, setValidstate] = React.useState({
    email: true,
    firstname: true,
    lastname: true,
    phonenumber: true,
  });
  let [validumetastate, setValidumetastate] = React.useState({
    namePrefix: true,
    firstnameEN: true,
    lastnameEN: true,
    dateOfBirth: true,
    recidenceAmphoe: true,
    recidenceProvince: true,
    jobStatus: true,
    studyLevel: true,
    studyPlace: true,
    studyPlaceProvince: true,
    parentName: true,
    parentPhonenumber: true,
    studyHighschoolPlan: true,
    otherHighschoolPlan: true,
    studyHighschoolLevel: true,
    studyYear: true,
    studyFaculty: true,
    otherFaculty: true,
    studyGroup: true,
    studyBranch: true,
    otherStudyBranch: true,
    maxStudyLevel: true,
    graduatedUniversity: true,
    jobType: true,
    otherOccupation: true,
    organizationName: true,
    jobPosition: true,
    jobDivision: true,
    jobAge: true,
    bestProgrammingLanguage: true,
    otherBestProgrammingLanguage: true,
    bestProgrammingLevel: true,
    willDevelop: true,
    haveAIExp: true,
    interestedLV2: true,
    acceptRecording: true,
    otherTarget: true,
    otherSource: true,

    // // for studying
    // studyLevel: true, //null,
    // studyPlace: true,
    // studyPlaceProvince: true, //null,
    // // for Children
    // parentName: true,
    // parentPhonenumber: true,
    // // for highschool
    // studyHighschoolPlan: true, //null,
    // otherHighschoolPlan: true,
    // // for normal highschool
    // studyHighschoolLevel: true, //null,
    // // for University
    // studyYear: true, //null,
    // studyFaculty: true, //null,
    // otherFaculty: true,
    // studyGroup: true, //null,
    // studyBranch: true, //null,
    // otherStudyBranch: true, //null,
    // // For grated level
    // researchArea: true,
    // // for working or unemployed
    // maxStudyLevel: true, //null,
    // graduatedUniversity: true,
    // // for working
    // jobType: true, //null,
    // organizationName: true,
    // jobPosition: true,
    // jobDivision: true,
    // jobAge: true,
  });
  const [iswaiting, setIswaiting] = React.useState(false);
  const [isregistersuccess, setIsregistersuccess] = React.useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "email") {
      if (
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value.replace(" ", "")
        ) ||
        !value.trim().endsWith("@gmail.com") ||
        /^[ก-๙]$/.test(value.charAt(0))
      ) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "phonenumber") {
      // value = value.replace(" ", "");
      if (value === "" || !/^0[0-9]{9}$/.test(value.replace(" ", ""))) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "firstname" || name === "lastname") {
      // value = value.replace(" ", "");
      if (
        !/^[ก-๙\-\s\.]{2,}$/.test(
          value.trim().replace(" ", "").replace(".", "").replace("-", "")
        )
      ) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else {
      if (value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }
  };
  const handleChangeUserMeta = (e) => {
    const { name, value } = e.target;
    setUsermeta((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "parentPhonenumber") {
      if (value === "" || !/^0[0-9]{9}$/.test(value.replace(" ", ""))) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "firstnameEN" || name === "lastnameEN") {
      // value = value.replace(" ", "");
      if (
        !/^[A-Z][a-z\-\s\.]{1,}$/.test(
          value.trim().replace(" ", "").replace(".", "").replace("-", "")
        )
      ) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "dateOfBirth") {
      if (
        value === "" ||
        // value.toString() > new Date(Date.now()).toISOString().slice(0, 10) ||
        value.toString() > "2017-01-01" ||
        value.toString() < "1934-01-01"
      ) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else {
      if (value === "") {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }
  };

  const handleChangeUserMetaCheckBox = (e) => {
    // const { name, checked } = e.target;
    let name = e.target.name.split("--");
    let checked = e.target.checked;
    if (checked) {
      let new_arr = usermeta[name[0]];
      new_arr.push(name[1]);
      setUsermeta((prevState) => ({
        ...prevState,
        [name[0]]: new_arr,
      }));
    } else {
      setUsermeta((prevState) => ({
        ...prevState,
        [name[0]]: usermeta[name[0]].filter(function (ee) {
          return ee !== name[1];
        }),
      }));
    }
  };
  function check_cond_before_submit() {
    let cond_state = true;
    let local_valid_state = { ...validstate };
    let local_umeta_valid_state = { ...validumetastate };
    let local_umeta_state = { ...usermeta };
    // ["email","firstname","lastname","phonenumber"]
    if (validstate.email && state.email.length !== 0) {
      local_valid_state.email = true;
    } else {
      local_valid_state.email = false;
      cond_state = false;
    }
    if (validstate.firstname && state.firstname.length !== 0) {
      local_valid_state.firstname = true;
    } else {
      local_valid_state.firstname = false;
      cond_state = false;
    }
    if (validstate.lastname && state.lastname.length !== 0) {
      local_valid_state.lastname = true;
    } else {
      local_valid_state.lastname = false;
      cond_state = false;
    }
    if (validstate.phonenumber && state.phonenumber.length !== 0) {
      local_valid_state.phonenumber = true;
    } else {
      local_valid_state.phonenumber = false;
      cond_state = false;
    }
    let arrName = [
      "namePrefix",
      "firstnameEN",
      "lastnameEN",
      "dateOfBirth",
      "recidenceAmphoe",
      "recidenceProvince",
      "jobStatus",
      "bestProgrammingLanguage",
      "willDevelop",
      "haveAIExp",
      "interestedLV2",
    ];
    for (const idx in arrName) {
      let name = arrName[idx];
      if (validumetastate[name] && usermeta[name] !== "") {
        local_umeta_valid_state[name] = true;
      } else {
        local_umeta_valid_state[name] = false;
        cond_state = false;
      }
    }
    if (usermeta.bestProgrammingLanguage === "") {
      if (
        validumetastate.bestProgrammingLevel &&
        usermeta.bestProgrammingLevel !== ""
      ) {
        local_umeta_valid_state.bestProgrammingLevel = true;
      } else {
        local_umeta_valid_state.bestProgrammingLevel = false;
        cond_state = false;
      }
    } else if (usermeta.bestProgrammingLanguage === "ไม่มี") {
      local_umeta_state.bestProgrammingLevel = "";
    } else if (usermeta.bestProgrammingLanguage === "อื่นๆ") {
      if (
        validumetastate.otherBestProgrammingLanguage &&
        usermeta.otherBestProgrammingLanguage !== ""
      ) {
        local_umeta_valid_state.otherBestProgrammingLanguage = true;
      } else {
        local_umeta_valid_state.otherBestProgrammingLanguage = false;
        cond_state = false;
      }
      if (
        validumetastate.bestProgrammingLevel &&
        usermeta.bestProgrammingLevel !== ""
      ) {
        local_umeta_valid_state.bestProgrammingLevel = true;
      } else {
        local_umeta_valid_state.bestProgrammingLevel = false;
        cond_state = false;
      }
    } else {
      local_umeta_state.otherBestProgrammingLanguage = "";
      if (
        validumetastate.bestProgrammingLevel &&
        usermeta.bestProgrammingLevel !== ""
      ) {
        local_umeta_valid_state.bestProgrammingLevel = true;
      } else {
        local_umeta_valid_state.bestProgrammingLevel = false;
        cond_state = false;
      }
    }
    if (usermeta.jobStatus === "เรียน") {
      let arrName = ["studyLevel"];
      for (const idx in arrName) {
        let name = arrName[idx];
        if (validumetastate[name] && usermeta[name] !== "") {
          local_umeta_valid_state[name] = true;
        } else {
          local_umeta_valid_state[name] = false;
          cond_state = false;
        }
      }
      if (
        usermeta.studyLevel === "ประถมศึกษา" ||
        usermeta.studyLevel === "มัธยมศึกษาตอนต้น"
      ) {
        let arrName = ["studyPlace", "studyPlaceProvince", "parentName"];
        for (const idx in arrName) {
          let name = arrName[idx];
          if (validumetastate[name] && usermeta[name] !== "") {
            local_umeta_valid_state[name] = true;
          } else {
            local_umeta_valid_state[name] = false;
            cond_state = false;
          }
        }
        if (
          validumetastate.parentPhonenumber &&
          (usermeta.parentPhonenumber !== "" ||
            !/^0[0-9]{9}$/.test(usermeta.parentPhonenumber.replace(" ", "")))
        ) {
          local_umeta_valid_state.parentPhonenumber = true;
        } else {
          local_umeta_valid_state.parentPhonenumber = false;
          cond_state = false;
        }
        local_umeta_state = {
          ...local_umeta_state,
          // for studying
          // studyLevel: "",
          // studyPlace: "",
          // studyPlaceProvince: "",
          // for Children
          // parentName: "",
          // parentPhonenumber: "",
          // for highschool
          studyHighschoolPlan: "",
          otherHighschoolPlan: "",
          // for normal highschool
          studyHighschoolLevel: "",
          // for University
          studyYear: "",
          studyFaculty: "",
          otherFaculty: "",
          studyGroup: "",
          studyBranch: "",
          otherStudyBranch: "",
          // For grated level
          researchArea: "",
          // for working or unemployed
          maxStudyLevel: "",
          graduatedUniversity: "",
          // for working
          jobType: "",
          otherOccupation: "",
          organizationName: "",
          jobPosition: "",
          jobDivision: "",
          jobAge: "",
        };
      } else if (usermeta.studyLevel === "มัธยมศึกษาตอนปลายหรือเทียบเท่า") {
        let arrName = [
          "studyPlace",
          "studyPlaceProvince",
          "studyHighschoolPlan",
          // "otherHighschoolPlan",
          usermeta.studyHighschoolPlan === "อื่นๆ"
            ? "otherHighschoolPlan"
            : null,
          "studyHighschoolLevel",
        ];
        for (const idx in arrName) {
          let name = arrName[idx];
          if (name !== null) {
            if (validumetastate[name] && usermeta[name] !== "") {
              local_umeta_valid_state[name] = true;
            } else {
              local_umeta_valid_state[name] = false;
              cond_state = false;
            }
          }
        }
        local_umeta_state = {
          ...local_umeta_state,
          // for studying
          // studyLevel: "",
          // studyPlace: "",
          // studyPlaceProvince: "",
          // for Children
          parentName: "",
          parentPhonenumber: "",
          // for highschool
          // studyHighschoolPlan: "",
          // otherHighschoolPlan: "",
          // for normal highschool
          // studyHighschoolLevel: "",
          // for University
          studyYear: "",
          studyFaculty: "",
          otherFaculty: "",
          studyGroup: "",
          studyBranch: "",
          otherStudyBranch: "",
          // For grated level
          researchArea: "",
          // for working or unemployed
          maxStudyLevel: "",
          graduatedUniversity: "",
          // for working
          jobType: "",
          otherOccupation: "",
          organizationName: "",
          jobPosition: "",
          jobDivision: "",
          jobAge: "",
        };
      } else if (usermeta.studyLevel === "อนุปริญญาหรือเทียบเท่า") {
        let arrName = [
          "studyPlace",
          "studyPlaceProvince",
          "studyHighschoolPlan",
          usermeta.studyHighschoolPlan === "อื่นๆ"
            ? "otherHighschoolPlan"
            : null,
        ];
        for (const idx in arrName) {
          let name = arrName[idx];
          if (name !== null) {
            if (validumetastate[name] && usermeta[name] !== "") {
              local_umeta_valid_state[name] = true;
            } else {
              local_umeta_valid_state[name] = false;
              cond_state = false;
            }
          }
        }
        local_umeta_state = {
          ...local_umeta_state,
          // for studying
          // studyLevel: "",
          // studyPlace: "",
          // studyPlaceProvince: "",
          // for Children
          parentName: "",
          parentPhonenumber: "",
          // for highschool
          // studyHighschoolPlan: "",
          // otherHighschoolPlan: "",
          // for normal highschool
          studyHighschoolLevel: "",
          // for University
          studyYear: "",
          studyFaculty: "",
          otherFaculty: "",
          studyGroup: "",
          studyBranch: "",
          otherStudyBranch: "",
          // For grated level
          researchArea: "",
          // for working or unemployed
          maxStudyLevel: "",
          graduatedUniversity: "",
          // for working
          jobType: "",
          otherOccupation: "",
          organizationName: "",
          jobPosition: "",
          jobDivision: "",
          jobAge: "",
        };
      } else if (
        usermeta.studyLevel === "ปริญญาตรี" ||
        usermeta.studyLevel === "ปริญญาโท" ||
        usermeta.studyLevel === "ปริญญาเอก"
      ) {
        let arrName = [
          // "studyYear",
          usermeta.studyLevel === "ปริญญาตรี" ? "studyYear" : null,
          "studyFaculty",
          "studyGroup",
          "studyBranch",
          usermeta.studyFaculty === "อื่นๆ" ? "otherFaculty" : null,
          usermeta.studyBranch === "อื่นๆ" ? "otherStudyBranch" : null,
        ];
        for (const idx in arrName) {
          let name = arrName[idx];
          if (name !== null) {
            if (validumetastate[name] && usermeta[name] !== "") {
              local_umeta_valid_state[name] = true;
            } else {
              local_umeta_valid_state[name] = false;
              cond_state = false;
            }
          }
        }
        local_umeta_state = {
          ...local_umeta_state,
          // for studying
          // studyLevel: "",
          // studyPlace: "",
          // studyPlaceProvince: "",
          // for Children
          parentName: "",
          parentPhonenumber: "",
          // for highschool
          studyHighschoolPlan: "",
          otherHighschoolPlan: "",
          // for normal highschool
          studyHighschoolLevel: "",
          // for University
          // studyYear: "",
          // studyFaculty: "",
          // otherFaculty: "",
          // studyGroup: "",
          // studyBranch: "",
          // otherStudyBranch: "",
          // For grated level
          // researchArea: "",
          // for working or unemployed
          maxStudyLevel: "",
          graduatedUniversity: "",
          // for working
          jobType: "",
          otherOccupation: "",
          organizationName: "",
          jobPosition: "",
          jobDivision: "",
          jobAge: "",
        };
      }
    } else if (usermeta.jobStatus === "ทำงาน") {
      let arrName = [
        "maxStudyLevel",
        "graduatedUniversity",
        "jobType",
        "organizationName",
        "jobPosition",
        "jobDivision",
        "jobAge",
      ];
      for (const idx in arrName) {
        let name = arrName[idx];
        if (validumetastate[name] && usermeta[name] !== "") {
          local_umeta_valid_state[name] = true;
        } else {
          local_umeta_valid_state[name] = false;
          cond_state = false;
        }
      }
      if (usermeta.jobType === "อื่นๆ" && usermeta.otherOccupation === "") {
        local_umeta_valid_state.otherOccupation = false;
        cond_state = false;
      } else if (usermeta.jobType !== "อื่นๆ") {
        local_umeta_state.otherOccupation = "";
        local_umeta_valid_state.otherOccupation = true;
      } else {
        local_umeta_valid_state.otherOccupation = true;
      }
      local_umeta_state = {
        ...local_umeta_state,
        // for studying
        studyLevel: "",
        studyPlace: "",
        studyPlaceProvince: "",
        // for Children
        parentName: "",
        parentPhonenumber: "",
        // for highschool
        studyHighschoolPlan: "",
        otherHighschoolPlan: "",
        // for normal highschool
        studyHighschoolLevel: "",
        // for University
        studyYear: "",
        studyFaculty: "",
        otherFaculty: "",
        studyGroup: "",
        studyBranch: "",
        otherStudyBranch: "",
        // For grated level
        researchArea: "",
        // for working or unemployed
        // maxStudyLevel: "",
        // graduatedUniversity: "",
        // for working
        // jobType: "",
        // organizationName: "",
        // jobPosition: "",
        // jobDivision: "",
        // jobAge: "",
      };
    } else if (usermeta.jobStatus === "ว่างงาน") {
      if (validumetastate.maxStudyLevel && usermeta.maxStudyLevel !== "") {
        local_umeta_valid_state.maxStudyLevel = true;
      } else {
        local_umeta_valid_state.maxStudyLevel = false;
        cond_state = false;
      }
      if (
        validumetastate.graduatedUniversity &&
        usermeta.graduatedUniversity !== ""
      ) {
        local_umeta_valid_state.graduatedUniversity = true;
      } else {
        local_umeta_valid_state.graduatedUniversity = false;
        cond_state = false;
      }
      local_umeta_state = {
        ...local_umeta_state,
        // for studying
        studyLevel: "",
        studyPlace: "",
        studyPlaceProvince: "",
        // for Children
        parentName: "",
        parentPhonenumber: "",
        // for highschool
        studyHighschoolPlan: "",
        otherHighschoolPlan: "",
        // for normal highschool
        studyHighschoolLevel: "",
        // for University
        studyYear: "",
        studyFaculty: "",
        otherFaculty: "",
        studyGroup: "",
        studyBranch: "",
        otherStudyBranch: "",
        // For grated level
        researchArea: "",
        // for working or unemployed
        // maxStudyLevel: "",
        // graduatedUniversity: "",
        // for working
        jobType: "",
        otherOccupation: "",
        organizationName: "",
        jobPosition: "",
        jobDivision: "",
        jobAge: "",
      };
    } else {
    }
    if (usermeta.target.includes("อื่นๆ")) {
      if (validumetastate.otherTarget && usermeta.otherTarget !== "") {
        local_umeta_valid_state.otherTarget = true;
      } else {
        local_umeta_valid_state.otherTarget = false;
        cond_state = false;
      }
    } else {
      local_umeta_state.otherTarget = "";
    }
    if (usermeta.source.includes("อื่นๆ")) {
      if (validumetastate.otherSource && usermeta.otherSource !== "") {
        local_umeta_valid_state.otherSource = true;
      } else {
        local_umeta_valid_state.otherSource = false;
        cond_state = false;
      }
    } else {
      local_umeta_state.otherSource = "";
    }
    setUsermeta(local_umeta_state);
    setValidstate(local_valid_state);
    setValidumetastate(local_umeta_valid_state);
    return cond_state;
  }
  async function sentregister() {
    if (check_cond_before_submit()) {
      setIswaiting(true);
      // Fetch data to be
      let response = await fetch(config.backend_url + "/api/register", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...state,
          firstname: usermeta.firstnameEN,
          lastname: usermeta.lastnameEN,
          email: state.email.trim(),
          phonenumber: postprocess_phone(state.phonenumber.trim()),
          usermeta: {
            email: state.email.trim(),
            phonenumber: postprocess_phone(state.phonenumber.trim()),
            firstnameTH: state.firstname,
            lastnameTH: state.lastname,
            ...usermeta,
          },
        }),
        // AESencrypt(
        //   JSON.stringify(state),
        //   config.aes_key
        // ),
      });
      if (response.status === 200) {
        setIsregistersuccess(true);
      } else {
        let data = await response.json();
        console.log(data);
        document.getElementById("warning").innerHTML = data["detail"];
        setIswaiting(false);
      }
    } else {
      document.getElementById("warning").innerHTML =
        "*กรุณากรอกข้อมูลให้ครบและถูกต้อง";
    }
  }
  function postprocess_phone(value) {
    return (
      value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6, 10)
    );
  }

  const SuperAIButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    fontFamily: "Poppins",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container
      // component="main"
      //  maxWidth="xs"
      // maxWidth="s"
      // maxWidth="300px"
      // style={{
      //   display: "flex",
      //   // alignItems: "center",
      //   justifyContent: "center",
      // }}
      >
        {/* <CssBaseline /> */}

        {is_register_open && (
          <div>
            {!isregistersuccess ? (
              <Box
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={4}
                  noValidate
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item>
                        <img
                          src="/superai_logo.png"
                          alt=""
                          style={{
                            maxWidth: "120px",
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <div
                              className="text-semi24 text-black-color"
                              style={{ textAlign: "center" }}
                            >
                              Registration Form SuperAI Engineer Season 4
                            </div>
                          </Grid>
                          <Grid item>
                            <div
                              className="text-link16 text-primary-color"
                              style={{ textAlign: "center" }}
                              onClick={() => {
                                window.open("https://superai.aiat.or.th/");
                              }}
                            >
                              ศึกษารายละเอียดโครงการฯ เพิ่มเติม
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container direction="column" spacing={3}>
                      <Grid item xs={12}>
                        <span className="text-semi16 text-black-color">
                          ข้อมูลประวัติของผู้สมัคร
                        </span>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              fullWidth
                              error={!validumetastate.namePrefix}
                            >
                              <InputLabel>คำนำหน้า</InputLabel>
                              <Select
                                value={usermeta.namePrefix}
                                name="namePrefix"
                                onChange={handleChangeUserMeta}
                                label="คำนำหน้า"
                              >
                                <MenuItem value={"เด็กชาย"}>เด็กชาย</MenuItem>
                                <MenuItem value={"เด็กหญิง"}>เด็กหญิง</MenuItem>
                                <MenuItem value={"นาย"}>นาย</MenuItem>
                                <MenuItem value={"นางสาว/นาง"}>
                                  นางสาว/นาง
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="ตำแหน่ง / ยศ (ถ้ามี)"
                              name="userrank"
                              value={usermeta.userrank}
                              onChange={handleChangeUserMeta}
                              helperText={
                                "ระบุเป็นอักษรย่อ ภาษาไทยและภาษาอังกฤษ เช่น ร.อ./Capt. "
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              error={!validstate.firstname}
                              id="firstname"
                              label="ชื่อ (ภาษาไทย)"
                              name="firstname"
                              value={state.firstname}
                              onChange={handleChange}
                              helperText={
                                !validstate.firstname && "กรุณาใส่ชื่อภาษาไทย"
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              error={!validstate.lastname}
                              id="lastname"
                              label="นามสกุล (ภาษาไทย)"
                              name="lastname"
                              value={state.lastname}
                              onChange={handleChange}
                              helperText={
                                !validstate.lastname && "กรุณาใส่นามสกุลภาษาไทย"
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              error={!validumetastate.firstnameEN}
                              label="First Name"
                              name="firstnameEN"
                              value={usermeta.firstnameEN}
                              onChange={handleChangeUserMeta}
                              helperText={
                                "ชื่อภาษาอังกฤษ โดยขึ้นต้นด้วยตัวพิมพ์ใหญ่"
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              error={!validumetastate.lastnameEN}
                              id="lastname"
                              label="Last Name"
                              name="lastnameEN"
                              value={usermeta.lastnameEN}
                              onChange={handleChangeUserMeta}
                              helperText={
                                "นามสกุลภาษาอังกฤษ โดยขึ้นต้นด้วยตัวพิมพ์ใหญ่"
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              error={!validumetastate.dateOfBirth}
                              label="วันเกิด"
                              name="dateOfBirth"
                              type="date"
                              id="datePickerId"
                              value={usermeta.dateOfBirth}
                              onChange={handleChangeUserMeta}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              helperText={
                                !validumetastate.dateOfBirth &&
                                "กรุณากรอกวันให้ถูกต้อง"
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              error={!validstate.phonenumber}
                              id="phonenumber"
                              label="Phone Number"
                              name="phonenumber"
                              value={state.phonenumber}
                              onChange={handleChange}
                              helperText={"กรอกในรูปแบบ 0xxxxxxxxx"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              error={!validstate.email}
                              id="email"
                              label="Email Address"
                              name="email"
                              autoComplete="email"
                              value={state.email}
                              onChange={handleChange}
                              helperText={
                                state.email !== ""
                                  ? !validstate.email
                                    ? state.email.endsWith("@gmail.com")
                                      ? "กรุณาตรวจสอบความถูกต้องของอีเมลล์"
                                      : "อีเมลล์ไม่ถูกต้อง กรุณากรอกอีเมลล์ที่เป็น @gmail.com"
                                    : ""
                                  : "กรุณากรอกอีเมลล์ที่เป็น @gmail.com"
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <span className="text-body16 text-black-color">
                          กรุณาระบุอำเภอและจังหวัดท่านพักอาศัยอยู่ในปัจจุบัน
                        </span>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              error={!validumetastate.recidenceAmphoe}
                              label="อำเภอ"
                              name="recidenceAmphoe"
                              value={usermeta.recidenceAmphoe}
                              onChange={handleChangeUserMeta}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              fullWidth
                              error={!validumetastate.recidenceProvince}
                            >
                              <InputLabel>จังหวัด</InputLabel>
                              <Select
                                value={usermeta.recidenceProvince}
                                name="recidenceProvince"
                                label="จังหวัด"
                                onChange={handleChangeUserMeta}
                              >
                                {config.provinceList.map((v) => {
                                  return <MenuItem value={v}>{v}</MenuItem>;
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container direction="column" spacing={3}>
                      <Grid item xs={12}>
                        <span className="text-semi16 text-black-color">
                          ข้อมูลระดับการศึกษาและการทำงาน
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <span className="text-body16 text-black-color">
                              สถานะภาพของท่านในปัจจุบัน
                            </span>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              name="jobStatus"
                              value={usermeta.jobStatus}
                              onChange={handleChangeUserMeta}
                              row
                            >
                              <FormControlLabel
                                value="เรียน"
                                control={<Radio />}
                                label="เรียน"
                              />
                              <FormControlLabel
                                value="ทำงาน"
                                control={<Radio />}
                                label="ทำงาน"
                              />
                              <FormControlLabel
                                value="ว่างงาน"
                                control={<Radio />}
                                label="ว่างงาน"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                      {usermeta.jobStatus === "เรียน" && (
                        <Grid item>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormControl
                                fullWidth
                                error={!validumetastate.studyLevel}
                              >
                                <InputLabel>กำลังการศึกษาในระดับ</InputLabel>
                                <Select
                                  fullWidth
                                  value={usermeta.studyLevel}
                                  name="studyLevel"
                                  label="กำลังการศึกษาในระดับ"
                                  onChange={handleChangeUserMeta}
                                >
                                  {[
                                    "ประถมศึกษา",
                                    "มัธยมศึกษาตอนต้น",
                                    "มัธยมศึกษาตอนปลายหรือเทียบเท่า",
                                    "อนุปริญญาหรือเทียบเท่า",
                                    "ปริญญาตรี",
                                    "ปริญญาโท",
                                    "ปริญญาเอก",
                                  ].map((v) => {
                                    return <MenuItem value={v}>{v}</MenuItem>;
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="สถาบันการศึกษา"
                                name="studyPlace"
                                value={usermeta.studyPlace}
                                onChange={handleChangeUserMeta}
                                helperText="ให้ใส่โรงเรียน หรือมหาวิทยาลัยนำหน้าชื่อสถาบันการศึกษา"
                                error={!validumetastate.studyPlace}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                fullWidth
                                error={!validumetastate.studyPlaceProvince}
                              >
                                <InputLabel>
                                  จังหวัดที่ตั้งของสถาบันการศึกษา
                                </InputLabel>
                                <Select
                                  fullWidth
                                  value={usermeta.studyPlaceProvince}
                                  name="studyPlaceProvince"
                                  label="จังหวัดที่ตั้งของสถาบันการศึกษา"
                                  onChange={handleChangeUserMeta}
                                >
                                  {config.provinceList.map((v) => {
                                    return <MenuItem value={v}>{v}</MenuItem>;
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            {(usermeta.studyLevel === "ประถมศึกษา" ||
                              usermeta.studyLevel === "มัธยมศึกษาตอนต้น") && (
                              <Grid item xs={12}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="ชื่อ - นามสกุลผู้ปกครอง"
                                      name="parentName"
                                      value={usermeta.parentName}
                                      onChange={handleChangeUserMeta}
                                      error={!validumetastate.parentName}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      label="เบอร์โทรศัพท์ผู้ปกครอง"
                                      name="parentPhonenumber"
                                      value={usermeta.parentPhonenumber}
                                      onChange={handleChangeUserMeta}
                                      helperText="กรอกในรูปแบบ 0xxxxxxxxx"
                                      error={!validumetastate.parentPhonenumber}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {(usermeta.studyLevel ===
                              "มัธยมศึกษาตอนปลายหรือเทียบเท่า" ||
                              usermeta.studyLevel ===
                                "อนุปริญญาหรือเทียบเท่า") && (
                              <Grid item xs={12}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <FormControl
                                      fullWidth
                                      error={
                                        !validumetastate.studyHighschoolPlan
                                      }
                                    >
                                      <InputLabel>แผนการเรียน</InputLabel>
                                      <Select
                                        value={usermeta.studyHighschoolPlan}
                                        name="studyHighschoolPlan"
                                        label="แผนการเรียน"
                                        onChange={handleChangeUserMeta}
                                      >
                                        {[
                                          "สายวิทย์-คณิต",
                                          "สายศิลป์-คำนวณ",
                                          "สายศิลป์-ภาษา",
                                          "สายคอมพิวเตอร์ธุรกิจ",
                                          "สายอิเล็กทรอนิกส์",
                                          "สายไฟฟ้ากำลัง",
                                          "สายเครื่องกล",
                                          "อื่นๆ",
                                        ].map((v) => {
                                          return (
                                            <MenuItem value={v}>{v}</MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    {usermeta.studyHighschoolPlan ===
                                      "อื่นๆ" && (
                                      <TextField
                                        fullWidth
                                        label="ระบุแผนการเรียน"
                                        name="otherHighschoolPlan"
                                        value={usermeta.otherHighschoolPlan}
                                        onChange={handleChangeUserMeta}
                                        error={
                                          !validumetastate.otherHighschoolPlan
                                        }
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {usermeta.studyLevel ===
                              "มัธยมศึกษาตอนปลายหรือเทียบเท่า" && (
                              <Grid item xs={12}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <FormControl
                                      fullWidth
                                      error={
                                        !validumetastate.studyHighschoolLevel
                                      }
                                    >
                                      <InputLabel>ระดับชั้น</InputLabel>
                                      <Select
                                        value={usermeta.studyHighschoolLevel}
                                        name="studyHighschoolLevel"
                                        label="ระดับชั้น"
                                        onChange={handleChangeUserMeta}
                                      >
                                        {[
                                          "ม.4 หรือเทียบเท่า",
                                          "ม.5 หรือเทียบเท่า",
                                          "ม.6 หรือเทียบเท่า",
                                        ].map((v) => {
                                          return (
                                            <MenuItem value={v}>{v}</MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {(usermeta.studyLevel === "ปริญญาตรี" ||
                              usermeta.studyLevel === "ปริญญาโท" ||
                              usermeta.studyLevel === "ปริญญาเอก") && (
                              <Grid item xs={12}>
                                <Grid container spacing={3}>
                                  {usermeta.studyLevel === "ปริญญาตรี" && (
                                    <Grid item xs={12} sm={6}>
                                      <FormControl
                                        fullWidth
                                        error={!validumetastate.studyYear}
                                      >
                                        <InputLabel>ชั้นปี</InputLabel>
                                        <Select
                                          value={usermeta.studyYear}
                                          name="studyYear"
                                          label="ชั้นปี"
                                          onChange={handleChangeUserMeta}
                                        >
                                          {["1", "2", "3", "4 หรือมากกว่า"].map(
                                            (v) => {
                                              return (
                                                <MenuItem value={v}>
                                                  {v}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  )}
                                  <Grid item xs={12} sm={6}>
                                    <FormControl
                                      fullWidth
                                      error={!validumetastate.studyFaculty}
                                    >
                                      <InputLabel>คณะ</InputLabel>
                                      <Select
                                        value={usermeta.studyFaculty}
                                        name="studyFaculty"
                                        label="คณะ"
                                        onChange={handleChangeUserMeta}
                                      >
                                        {[
                                          "คณะเกษตรศาสตร์",
                                          "คณะครุศาสตร์อุตสาหกรรม",
                                          "คณะเทคโนโลยี",
                                          "คณะเทคโนโลยีสารสนเทศ",
                                          "คณะประมง",
                                          "คณะวิทยาศาสตร์",
                                          "คณะวิศวกรรมศาสตร์",
                                          "คณะสิ่งแวดล้อม",
                                          "คณะกายภาพบำบัด",
                                          "คณะการแพทย์แผนไทย",
                                          "คณะทันตแพทยศาสตร์",
                                          "คณะเทคนิคการแพทย์",
                                          "คณะแพทยศาสตร์",
                                          "คณะพยาบาลศาสตร์",
                                          "คณะเภสัชศาสตร์",
                                          "คณะสหเวชศาสตร์",
                                          "คณะสัตวแพทยศาสตร์",
                                          "คณะสาธารณสุขศาสตร์",
                                          "คณะนิติศาสตร์",
                                          "คณะนิเทศศาสตร์",
                                          "คณะบริหารธุรกิจและการบัญชี",
                                          "คณะมนุษยศาสตร์",
                                          "คณะรัฐศาสตร์",
                                          "คณะศิลปกรรมศาสตร์",
                                          "คณะศึกษาศาสตร์",
                                          "คณะเศรษฐศาสตร์",
                                          "คณะสถาปัตยกรรมศาสตร์",
                                          "คณะสังคมศาสตร์",
                                          "คณะสังคมสงเคราะห์ศาสตร์",
                                          "อื่นๆ",
                                        ].map((v) => {
                                          return (
                                            <MenuItem value={v}>{v}</MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  {usermeta.studyFaculty === "อื่นๆ" && (
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        fullWidth
                                        label="ระบุคณะหรือโครงการ"
                                        name="otherFaculty"
                                        value={usermeta.otherFaculty}
                                        onChange={handleChangeUserMeta}
                                        error={!validumetastate.otherFaculty}
                                      />
                                    </Grid>
                                  )}
                                  <Grid item xs={12} sm={6}>
                                    <FormControl
                                      fullWidth
                                      error={!validumetastate.studyGroup}
                                    >
                                      <InputLabel>กลุ่มสาขาวิชา</InputLabel>
                                      <Select
                                        value={usermeta.studyGroup}
                                        name="studyGroup"
                                        label="กลุ่มสาขาวิชา"
                                        onChange={handleChangeUserMeta}
                                      >
                                        {[
                                          "วิศวกรรมศาสตร์ วิทยาศาสตร์และเทคโนโลยี",
                                          "วิทยาศาสตร์สุขภาพ",
                                          "สังคมสงเคราะห์ ศิลปศาสตร์ มนุษยศาสตร์ ศึกษาศาสตร์",
                                          "ศิลปกรรมศาสตร์ สถาปัตยกรรมศาสตร์",
                                          "เกษตรศาสตร์",
                                        ].map((v) => {
                                          return (
                                            <MenuItem value={v}>{v}</MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  {usermeta.studyGroup && (
                                    <Grid item xs={12} sm={6}>
                                      <FormControl
                                        fullWidth
                                        error={!validumetastate.studyBranch}
                                      >
                                        <InputLabel>
                                          {"สาขา" + usermeta.studyGroup}
                                        </InputLabel>
                                        <Select
                                          value={usermeta.studyBranch}
                                          name="studyBranch"
                                          label={"สาขา" + usermeta.studyGroup}
                                          onChange={handleChangeUserMeta}
                                        >
                                          {config.branchList[
                                            usermeta.studyGroup
                                          ].map((v) => {
                                            return (
                                              <MenuItem value={v}>{v}</MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  )}
                                  {usermeta.studyBranch === "อื่นๆ" && (
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        fullWidth
                                        label="สาขาวิชา อื่น ๆ"
                                        name="otherStudyBranch"
                                        value={usermeta.otherStudyBranch}
                                        onChange={handleChangeUserMeta}
                                        error={
                                          !validumetastate.otherStudyBranch
                                        }
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            )}
                            {(usermeta.studyLevel === "ปริญญาโท" ||
                              usermeta.studyLevel === "ปริญญาเอก") && (
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="หัวข้อวิจัย"
                                  name="researchArea"
                                  value={usermeta.researchArea}
                                  onChange={handleChangeUserMeta}
                                  helperText="หัวข้อวิจัยที่กำลังทำอยู่ แม้ว่าหัวข้อนั้นยังไม่ได้สอบก็ตาม เป็นเพียงข้อมูลเพิ่มเติมเท่านั้น"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {usermeta.jobStatus === "ทำงาน" && (
                        <Grid item>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                fullWidth
                                error={!validumetastate.maxStudyLevel}
                              >
                                <InputLabel>{"การศึกษาสูงสุด"}</InputLabel>
                                <Select
                                  value={usermeta.maxStudyLevel}
                                  name="maxStudyLevel"
                                  label="การศึกษาสูงสุด"
                                  onChange={handleChangeUserMeta}
                                >
                                  {[
                                    "ประถมศึกษา",
                                    "มัธยมศึกษาตอนต้น",
                                    "มัธยมศึกษาตอนปลายหรือเทียบเท่า",
                                    "อนุปริญญาหรือเทียบเท่า",
                                    "ปริญญาตรี",
                                    "ปริญญาโท",
                                    "ปริญญาเอก",
                                  ].map((v) => {
                                    return <MenuItem value={v}>{v}</MenuItem>;
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="สถาบันที่จบการศึกษา"
                                name="graduatedUniversity"
                                value={usermeta.graduatedUniversity}
                                onChange={handleChangeUserMeta}
                                helperText="ให้ใส่โรงเรียน หรือมหาวิทยาลัยนำหน้าชื่อสถาบันการศึกษา"
                                error={!validumetastate.graduatedUniversity}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                fullWidth
                                error={!validumetastate.jobType}
                              >
                                <InputLabel>{"ประเภทอาชีพ"}</InputLabel>
                                <Select
                                  value={usermeta.jobType}
                                  name="jobType"
                                  label="ประเภทอาชีพ"
                                  onChange={handleChangeUserMeta}
                                >
                                  {[
                                    "พนักงานบริษัทเอกชน",
                                    "พนักงานองค์กรภาครัฐ",
                                    "เจ้าของธุรกิจ",
                                    "วิทยากร ครู หรืออาจารย์",
                                    "อื่นๆ",
                                  ].map((v) => {
                                    return <MenuItem value={v}>{v}</MenuItem>;
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            {usermeta.jobType === "อื่นๆ" && (
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label="อาชีพ อื่น ๆ"
                                  name="otherOccupation"
                                  value={usermeta.otherOccupation}
                                  onChange={handleChangeUserMeta}
                                  helperText={
                                    !validumetastate.otherOccupation &&
                                    "กรุณากรอกอาชีพ"
                                  }
                                  error={!validumetastate.otherOccupation}
                                />
                              </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="ชื่อหน่วยงาน"
                                name="organizationName"
                                value={usermeta.organizationName}
                                onChange={handleChangeUserMeta}
                                error={!validumetastate.organizationName}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="ตำแหน่งงาน"
                                name="jobPosition"
                                value={usermeta.jobPosition}
                                onChange={handleChangeUserMeta}
                                error={!validumetastate.jobPosition}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="ฝ่าย / แผนก"
                                name="jobDivision"
                                value={usermeta.jobDivision}
                                onChange={handleChangeUserMeta}
                                helperText="หากไม่มีให้ระบุ - (ขีด)"
                                error={!validumetastate.jobDivision}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="อายุงาน (ปี)"
                                name="jobAge"
                                value={usermeta.jobAge}
                                onChange={handleChangeUserMeta}
                                error={!validumetastate.jobAge}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {usermeta.jobStatus === "ว่างงาน" && (
                        <Grid item>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                fullWidth
                                error={!validumetastate.maxStudyLevel}
                              >
                                <InputLabel>{"การศึกษาสูงสุด"}</InputLabel>
                                <Select
                                  value={usermeta.maxStudyLevel}
                                  name="maxStudyLevel"
                                  label="การศึกษาสูงสุด"
                                  onChange={handleChangeUserMeta}
                                >
                                  {[
                                    "ประถมศึกษา",
                                    "มัธยมศึกษาตอนต้น",
                                    "มัธยมศึกษาตอนปลายหรือเทียบเท่า",
                                    "อนุปริญญาหรือเทียบเท่า",
                                    "ปริญญาตรี",
                                    "ปริญญาโท",
                                    "ปริญญาเอก",
                                  ].map((v) => {
                                    return <MenuItem value={v}>{v}</MenuItem>;
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="สถาบันที่จบการศึกษา"
                                name="graduatedUniversity"
                                value={usermeta.graduatedUniversity}
                                onChange={handleChangeUserMeta}
                                helperText="ให้ใส่โรงเรียน หรือมหาวิทยาลัยนำหน้าชื่อสถาบันการศึกษา"
                                error={!validumetastate.graduatedUniversity}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className="text-semi16 text-black-color">
                          หัวข้อที่ถนัดทางด้านการเขียนโปรแกรม
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={!validumetastate.bestProgrammingLanguage}
                        >
                          <InputLabel>
                            {"ภาษาทางคอมพิวเตอร์ที่ถนัดที่สุด"}
                          </InputLabel>
                          <Select
                            value={usermeta.bestProgrammingLanguage}
                            name="bestProgrammingLanguage"
                            label="ภาษาทางคอมพิวเตอร์ที่ถนัดที่สุด"
                            onChange={handleChangeUserMeta}
                          >
                            {config.progrmmingList.map((v) => {
                              return <MenuItem value={v}>{v}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      {usermeta.bestProgrammingLanguage === "อื่นๆ" && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={
                              !validumetastate.otherBestProgrammingLanguage
                            }
                            fullWidth
                            label="ภาษาอื่นๆ"
                            name="otherBestProgrammingLanguage"
                            value={usermeta.otherBestProgrammingLanguage}
                            onChange={handleChangeUserMeta}
                          />
                        </Grid>
                      )}
                      {usermeta.bestProgrammingLanguage !== "ไม่มี" && (
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            error={!validumetastate.bestProgrammingLevel}
                          >
                            <InputLabel>{"ระดับความเชี่ยวชาญ"}</InputLabel>
                            <Select
                              value={usermeta.bestProgrammingLevel}
                              name="bestProgrammingLevel"
                              label="ระดับความเชี่ยวชาญ"
                              onChange={handleChangeUserMeta}
                            >
                              {["มาก", "ปานกลาง", "น้อย"].map((v) => {
                                return <MenuItem value={v}>{v}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="ความเชี่ยวชาญอื่น ๆ"
                          name="expertTopic"
                          value={usermeta.expertTopic}
                          onChange={handleChangeUserMeta}
                          helperText="สามารถระบุความเชี่ยวชาญนอกจากเรื่องคอมพิวเตอร์ได้"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className="text-semi16 text-black-color">
                          หัวข้อที่ถนัดทางด้านปัญญาประดิษฐ์
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <FormControl>
                              {[
                                "Natural Language Processing",
                                "Image Processing",
                                "Signal Processing",
                                "Data Science",
                                "Robotics",
                                "Internet of Things",
                                "Health Care",
                                "Climate Change",
                              ].map((v) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={usermeta.AIExpertTopics.includes(
                                          v
                                        )}
                                        onChange={handleChangeUserMetaCheckBox}
                                        name={"AIExpertTopics--" + v}
                                        color="primary"
                                      />
                                    }
                                    label={v}
                                  />
                                );
                              })}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className="text-semi16 text-black-color">
                          ในการเข้าร่วมโครงการนี้
                          คุณมีเป้าหมายในด้านปัญญาประดิษฐ์ในสาขาใดบ้าง
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <FormControl>
                              {[
                                "เป็นนวัตกรหรือวิศวกรด้าน AI",
                                "เป็นผู้ประกอบการวิสาหกิจที่เกี่ยวกับ AI",
                                "เป็นนักวิจัย AI",
                                "เป็นวิทยากร ครู หรืออาจารย์ทางด้าน AI",
                                "เป็นผู้มีความรู้พื้นฐานทางด้าน AI",
                                "อื่นๆ",
                              ].map((v) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={usermeta.target.includes(v)}
                                        onChange={handleChangeUserMetaCheckBox}
                                        name={"target--" + v}
                                        color="primary"
                                      />
                                    }
                                    label={v}
                                  />
                                );
                              })}
                            </FormControl>
                          </Grid>
                          {usermeta.target.includes("อื่นๆ") && (
                            <Grid item>
                              <TextField
                                fullWidth
                                label="เป้าหมายอื่น ๆ"
                                name="otherTarget"
                                value={usermeta.otherTarget}
                                onChange={handleChangeUserMeta}
                                error={!validumetastate.otherTarget}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <span className="text-body16 text-black-color">
                              ท่านสนใจจะพัฒนางานต่อยอดสู่ผู้ใช้งานจริงหลังเสร็จสิ้นโครงการหรือไม่
                            </span>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              name="willDevelop"
                              value={usermeta.willDevelop}
                              onChange={handleChangeUserMeta}
                            >
                              <FormControlLabel
                                value="ต่อยอด"
                                control={<Radio />}
                                label="ต่อยอด"
                              />
                              <FormControlLabel
                                value="ไม่ต่อยอด"
                                control={<Radio />}
                                label="ไม่ต่อยอด"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <span
                              className="text-body16 text-black-color"
                              style={
                                !validumetastate.haveAIExp
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              ท่านมีประสบการณ์ทำงานเกี่ยวกับด้านปัญญาประดิษฐ์หรือไม่
                            </span>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              name="haveAIExp"
                              value={usermeta.haveAIExp}
                              onChange={handleChangeUserMeta}
                            >
                              <FormControlLabel
                                value="ใช่"
                                control={<Radio />}
                                label="ใช่"
                              />
                              <FormControlLabel
                                value="ไม่ใช่"
                                control={<Radio />}
                                label="ไม่ใช่"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <span
                              className="text-body16 text-black-color"
                              style={
                                !validumetastate.interestedLV2
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              ท่านมีความตั้งใจสอบคัดเลือกเข้าสู่รอบที่ 2 หรือไม่
                            </span>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              name="interestedLV2"
                              value={usermeta.interestedLV2}
                              onChange={handleChangeUserMeta}
                              error
                            >
                              <FormControlLabel
                                value="มี"
                                control={<Radio />}
                                label="มี"
                              />
                              <FormControlLabel
                                value="ไม่มี"
                                control={<Radio />}
                                label="ไม่มี"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <span
                              className="text-body16 text-black-color"
                              style={
                                !validumetastate.acceptRecording
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              ในระหว่างการจัดอบรมออนไลน์
                              ท่านยินยอมที่จะให้ทางโครงการบันทึกข้อมูล รูปภาพ
                              เสียง
                              ของท่านซึ่งอาจจะอยู่ในคลิปวีดิโอในระหว่างการอบรมหรือไม่
                            </span>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              name="acceptRecording"
                              value={usermeta.acceptRecording}
                              onChange={handleChangeUserMeta}
                            >
                              <FormControlLabel
                                value="ยินยอม"
                                control={<Radio />}
                                label="ยินยอม"
                              />
                              <FormControlLabel
                                value="ไม่ยินยอม"
                                control={<Radio />}
                                label="ไม่ยินยอม"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <span className="text-semi16 text-black-color">
                              ท่านทราบข่าวการสมัครจากที่ใด
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl>
                              {[
                                "Website สมาคมปัญญาประดิษฐ์ประเทศไทย",
                                "Facebook เพจ สมาคมปัญญาประดิษฐ์ประเทศไทย",
                                "AIAT MOOC",
                                "Youtube AIAT",
                                "Facebook เพจ Super AI Engineer Development Program",
                                "Youtube Super AI Engineer",
                                "Line Official Account SuperAI Engineer",
                                "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคเหนือ",
                                "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคกลาง",
                                "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคใต้",
                                "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคตะวันออก",
                              ].map((v) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={usermeta.source.includes(v)}
                                        onChange={handleChangeUserMetaCheckBox}
                                        name={"source--" + v}
                                        color="primary"
                                      />
                                    }
                                    label={v}
                                  />
                                );
                              })}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={1}>
                              <Grid item>
                                <FormControl>
                                  {[
                                    "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคตะวันตก",
                                    "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคตะวันออกเฉียงเหนือ",
                                    "ผู้เข้าโครงการ Super AI Engineer",
                                    "IT24Hrs - ไอที 24 ชั่วโมง by ปานระพี (ทุกช่องทาง)",
                                    "ครอบครัว / ญาติ",
                                    "ครู / อาจารย์ / โรงเรียน",
                                    "เพื่อน / รุ่นพี่ / รุ่นน้อง",
                                    "Line กลุ่ม",
                                    "ที่ทำงาน",
                                    "เพจ 9arm",
                                    "อื่นๆ",
                                  ].map((v) => {
                                    return (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={usermeta.source.includes(
                                              v
                                            )}
                                            onChange={
                                              handleChangeUserMetaCheckBox
                                            }
                                            name={"source--" + v}
                                            color="primary"
                                          />
                                        }
                                        label={v}
                                      />
                                    );
                                  })}
                                </FormControl>
                              </Grid>
                              {usermeta.source.includes("อื่นๆ") && (
                                <Grid item>
                                  <TextField
                                    fullWidth
                                    label="ท่านทราบข่าวจากอื่น ๆ"
                                    name="otherSource"
                                    value={usermeta.otherSource}
                                    onChange={handleChangeUserMeta}
                                    error={!validumetastate.otherSource}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className="text-semi16 text-black-color">
                          กรุณาระบุข้อความยินยอมการให้ข้อมูลส่วนบุคคล
                          โดยการเก็บข้อมูลส่วนบุคคลครั้งนี้
                          จะถูกจัดเก็บด้วยความระมัดระวัง
                          และอยู่ภายใต้เงื่อนไขและข้อจำกัดต่าง ๆ
                          ที่เกี่ยวกับการดำเนินโครงการนี้เท่านั้น
                          โดยมิได้ใช้เพื่อการค้าหรือเพื่อธุรกิจใด ๆ
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <span className="text-body16 text-black-color">
                              ข้าพเจ้าตกลงยินยอมให้โครงการจัดเก็บข้อมูลส่วนบุคคลที่ข้าพเจ้าได้ให้ไว้ในการลงทะเบียนเข้าร่วมโครงการ
                              และยินยอมให้เปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าต่อหน่วยงานของรัฐ
                              หรือผู้ให้ทุน
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={usermeta.acceptNDA}
                                  onChange={(e) => {
                                    let name = e.target.name;
                                    let checked = e.target.checked;
                                    setUsermeta((prevState) => ({
                                      ...prevState,
                                      [name]: checked,
                                    }));
                                  }}
                                  name={"acceptNDA"}
                                  color="primary"
                                />
                              }
                              label={"ยินยอม"}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SuperAIButton
                              fullWidth
                              variant="contained"
                              onClick={sentregister}
                              disabled={
                                !usermeta.acceptNDA ||
                                usermeta.acceptRecording !== "ยินยอม"
                              }
                            >
                              {!iswaiting ? (
                                <div>Register</div>
                              ) : (
                                <div>
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />{" "}
                                  Waiting...
                                </div>
                              )}
                            </SuperAIButton>
                          </Grid>
                          <Grid item xs={12}>
                            <div
                              id="warning"
                              className="text-body16"
                              style={{ color: "red" }}
                            ></div>
                          </Grid>
                          <Grid item xs={12}>
                            {(!usermeta.acceptNDA ||
                              usermeta.acceptRecording !== "ยินยอม") && (
                              <p
                                className="text-body16"
                                style={{ color: "red" }}
                              >
                                *กรุณากดยินยอมให้โครงการ จัดเก็บข้อมูลส่วนบุคคล
                                และ บันทึกข้อมูล รูปภาพ เสียง
                                ของท่านซึ่งอาจจะอยู่ในคลิปวีดิโอในระหว่างการอบรม
                              </p>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // maxWidth: "480px",
                }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "64px",
                    width: "100%",
                    margin: "auto",
                    color: "#49AA19",
                  }}
                />
                <div
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  Congratulations!
                </div>
                <div
                  style={{
                    marginBottom: "16px",
                    padding: "16px",
                    textAlign: "center",
                    fontSize: "16px",
                    maxWidth: "540px",
                  }}
                >
                  {/* You have successfully register to AI/Robotics for All (Season 3).
                We have sent your username and password in your email. If you can
                not see, please check in your junk mail. */}
                  {/* You have successfully register to AI/Robotics for All (Season 3).
                We will send your username and password in your email in 24 hours.
                If you can not see, please check in your junk mail.
                <br /> */}
                  คุณได้ทำการสมัครเข้าโครงการ SuperAI Engineer (Season 4)
                  เป็นที่เรียบร้อยแล้ว เราจะส่ง username และ password
                  สำหรับเรียน mooc ให้คุณภายใน 24 ชั่วโมง
                  ถ้าคุณไม่เห็นกรุณาตรวจสอบในเมลขยะ
                </div>
              </Box>
            )}
          </div>
        )}
        {!is_register_open && (
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // maxWidth: "480px",
            }}
          >
            {/* <CheckCircleIcon
              style={{
                fontSize: "64px",
                width: "100%",
                margin: "auto",
                color: "#49AA19",
              }}
            /> */}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap={4}
              noValidate
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item>
                    <img
                      src="/superai_logo.png"
                      alt=""
                      style={{
                        maxWidth: "120px",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <div
                          className="text-semi24 text-black-color"
                          style={{ textAlign: "center" }}
                        >
                          Registration Form SuperAI Engineer Season 4
                        </div>
                      </Grid>
                      <Grid item>
                        <div
                          className="text-link16 text-primary-color"
                          style={{ textAlign: "center" }}
                          onClick={() => {
                            window.open("https://superai.aiat.or.th/");
                          }}
                        >
                          ศึกษารายละเอียดโครงการฯ เพิ่มเติม
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                className="card-container"
                style={{ paddingTop: "64px", paddingBottom: "64px" }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  spacing={3}
                >
                  <Grid item>
                    <DoNotDisturbOnIcon
                      style={{
                        fontSize: "64px",
                        width: "100%",
                        margin: "auto",
                        color: "#ff0000",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <span className="text-semi24 text-black-color">ขออภัย</span>
                  </Grid>
                  <Grid item style={{ maxWidth: "540px" }}>
                    <span className="text-body16 text-black-color">
                      อยู่ระหว่างปิดระบบลงทะเบียน พบกันใหม่ เร็ว ๆ นี้
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
}
